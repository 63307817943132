import { useQuery, useSubscription } from '@apollo/client';
import Link from 'next/link';
import { NavDropdown } from 'react-bootstrap';
import {
  UserChatNewMessagesDocument,
  UserChatNewMessagesSubscriptionDocument,
} from '../../types/graphqlOperations';

type Props = {
  userID: string;
  iconOnly?: boolean;
};

/**
 * Customer Inbox Link
 *
 * Includes the number of messages.
 *
 * @example
 * Messages (2)
 */
export function CustomerInboxLink({ userID }: Props) {
  // Get the number of user messages
  const queryResult = useQuery(UserChatNewMessagesDocument, {
    variables: { userID },
  });
  const unreadMessages = queryResult.data?.user.chatMessageCount.unreadMessages;

  // Subscribe so that this number is always up to date
  // Note we don't need to do anything with the response, as the cache is update automatically which means the data
  // from the query above will be updated as well.
  useSubscription(UserChatNewMessagesSubscriptionDocument, {
    variables: { userID },
  });

  return (
    <>
      <Link href={`/me/${userID}/messages`} passHref legacyBehavior>
        <NavDropdown.Item data-cy="inbox" className="fw-bold text-black-50">
          Messages
          {unreadMessages > 0 ? ` (${unreadMessages})` : ''}
        </NavDropdown.Item>
      </Link>
    </>
  );
}
