import { Store } from '@website/Store';
import { useContext } from 'react';

export default function useIsAdmin() {
  const { state } = useContext(Store);

  const isAdmin = state.user?.roles.includes('admin');

  return isAdmin;
}
