import { useQuery } from '@tanstack/react-query';
import useIsAdmin from '@website/hooks/useIsAdmin';
import { ContentfulReviewsResponse } from '@website/pages/api/contentful/reviews';
import Link from 'next/link';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaQuoteLeft } from 'react-icons/fa';
import { MdOutlineStar } from 'react-icons/md';
import Avatar from '../directoryTheme/Avatar';
import TrustPilotWidget from '../trustpilot/TrustPilotWidget';

export default function ReviewsModule() {
  const { data, isLoading, error } = useQuery<ContentfulReviewsResponse>({
    queryKey: ['reviews', 'reviewsModuleCollection'],
    queryFn: async () => {
      const response = await fetch('/api/contentful/reviews');
      return response.json();
    },
  });

  const reviewsToLastHundredth = useMemo(() => {
    if (!data) {
      return null;
    }

    return Math.floor(data.reviewStats.reviewCount / 100) * 100;
  }, [data]);

  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (error && !data) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <section className="position-relative mt-4">
      <Container className="py-5 text-center">
        <h2>
          Rated Excellent by over {reviewsToLastHundredth.toLocaleString()}{' '}
          travellers
        </h2>
        <p className="d-flex justify-content-center">
          <span>
            {[1, 2, 3, 4, 5].map((i) => (
              <MdOutlineStar size={30} key={i} className="text-primary" />
            ))}
          </span>
          <span className="ms-2 mt-1">
            4.9/5 ({data.reviewStats.reviewCount.toLocaleString()} reviews)
          </span>
        </p>
        <p>
          <Link href="/reviews" className="fw-bold">
            Read our reviews
          </Link>
        </p>
        <Row>
          {data.reviews.map((review) => (
            <Col
              className="p-4"
              xs={12}
              sm={6}
              lg={4}
              // eslint-disable-next-line no-underscore-dangle
              key={review._id}
            >
              <div
                className="bg-light rounded-3 p-4 text-start d-flex flex-column h-100"
                style={{
                  gap: '1rem',
                }}
              >
                <FaQuoteLeft size={25} />
                <h5>{review.subject}</h5>
                <p>{review.content}</p>
                <div className="d-flex">
                  <Avatar
                    alt={review.profilePicture.title}
                    image={review.profilePicture.url}
                    defaultImageText=""
                    cdn={process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL}
                  />
                  <div className="ms-2">
                    <div className="d-block text-muted">
                      {review.nameOfTheReview}
                    </div>
                    <Link target="_blank" href={review.linkToTrip}>
                      {review.tripName}
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <p className="text-center py-2 fw-bold">
          Also see our reviews on Trustpilot
        </p>
        <TrustPilotWidget
          templateId="5419b6ffb0d04a076446a9af"
          height="20px"
          className="mx-auto"
        />{' '}
      </Container>
    </section>
  );
}
